<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Lead(II) iodide precipitates from solution when lead(II) nitrate is mixed with potassium
        iodide with an equilibrium constant of
        <stemble-latex content="$1.02\times 10^8$" />
        at 298 K. Suppose 0.0163 mol of lead(II) nitrate was added to 250.0 mL of 0.325 M potassium
        iodide.
      </p>

      <p class="mb-2">
        a) Write the balanced net ionic equation for this reaction. For the equilibrium arrow, type
        &lt;=&gt;
      </p>

      <chemical-notation-input
        v-model="inputs.netIonicEqn"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) Determine the value of Q.</p>

      <calculation-input
        v-model="inputs.Q"
        class="mb-5"
        prepend-text="$\text{Q}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) How many moles of lead iodide would be produced if the reaction did not stop at
        equilibrium, but instead went to 100% completion?
      </p>

      <calculation-input
        v-model="inputs.molPbI2"
        class="mb-5"
        prepend-text="$\ce{PbI2}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) What concentration of iodide would remain if the reaction did not stop at equilibrium,
        but instead went to 100% completion?
      </p>

      <calculation-input
        v-model="inputs.concI"
        class="mb-5"
        prepend-text="$\ce{[I-]}:$"
        append-text="$\text{mol/L}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) This reaction actually stops at equilibrium and does not go all the way to 100%
        completion. Use the information you obtained in parts (c) and (d) to calculate the
        concentration of
        <chemical-latex content="Pb^2+(aq)" />
        that would remain in solution at equilibrium at 298K. Any assumptions required to solve this
        question can be used so long as they are valid.
      </p>

      <calculation-input
        v-model="inputs.concPb"
        class="mb-0"
        prepend-text="$\ce{[Pb^2+]}:$"
        append-text="$\text{mol/L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'Question464',
  components: {
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        netIonicEqn: null,
        Q: null,
        molPbI2: null,
        concI: null,
        concPb: null,
      },
    };
  },
};
</script>
